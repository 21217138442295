<template>
  <div class="order-management">
    <div class="prediction">
      <p style="font-size: 14px; margin-left: 16px; color: black">
        请选择物流方式
      </p>
      <select style="margin-top: 10px" class="nq-select">
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="京东">京东</option>
        <option value="其他">其他</option>
      </select>
      <nqForm
        :submitText="submitText"
        @submit="submitForm"
        @cancel="cancelForm"
        :values="formValue"
        :formList="[
          {
            label: '物流单号',
            key: 'orderNo',
            type: 'input',
            placeholder: '请输入物流单号',
          },
        ]"
      ></nqForm>
      <!-- <p style="margin-top: 8px;">请输入物流单号</p>
      <input type="text" v-model="orderNo" style="margin-top: 10px;"> -->
      <div class="notes">
        <p>
          一般情况下，您的物流只要写了入库码，我们将会帮您入库，无需您到货预报。
        </p>
        <p>
          由于物流签收后入库需要时间，当您的物流已经签收后过了1天仍未在此显示，或者您没有写入库码，则使用到货预报功能，后台会帮您查询并尽快安排入库。
        </p>
      </div>
    </div>
    <div style="text-align: center; margin-top: 30px">
      <button class="nq-button" style="margin-right: 50px">关闭</button>
      <button class="nq-button copy-button">继续提交</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'login',
  data() {
    return {
      showDetail: false,
      formValue: {
        orderNo: ''
      }
    }
  },

  mounted() {},
  methods: {
    submitForm(data) {
      console.log(data)
    },
    cancelForm() {}
  }
}
</script>
<style lang="less" scoped>
@import url("./order.less");
</style>
